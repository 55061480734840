import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import reportDatadogRum from './reportDatadogRum';
import { useFeatureFlag } from './ebss/hooks/useFeatureFlag';
import { BrandProvider } from './ebss/providers/brand/BrandProvider';
import './index.css';

reportDatadogRum({
  env: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const isFeatureEnabled = useFeatureFlag(false);
const brand = isFeatureEnabled('enable_boost-theme') ? 'boost' : 'ovo';
root.render(
  <React.StrictMode>
    <BrandProvider brand={brand}>
      <App />
    </BrandProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
