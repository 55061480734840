import { PrimaryCTAButton } from '@ovotech/nebula';
import React from 'react';
import { useTrack } from '../../analytics';
import './NextButton.css';

interface Props {
  onClick?: () => void;
  isSubmit?: boolean;
  trackOptions: [string, Record<string, unknown>?];
}

const NextButton: React.FC<Props> = ({ onClick, isSubmit, trackOptions: [event, payload] }) => {
  const track = useTrack();
  function handleClick() {
    track(event, payload);
    if (onClick) onClick();
  }
  return (
    <div className="next-button">
      <PrimaryCTAButton
        iconRight="chevron-right"
        data-test="next-button"
        onClick={handleClick}
        {...(isSubmit && { type: 'submit' })}
      >
        Next
      </PrimaryCTAButton>
    </div>
  );
};

export default NextButton;
