import React, { PropsWithChildren } from 'react';
import './FormSection.css';

interface Props {
  className?: string;
}

const FormSection: React.FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return (
    <section className={`form-section ${className ?? ''}`}>
      <div className="form-section_content" data-test="form-section-content">
        {children}
      </div>
    </section>
  );
};

export default FormSection;
