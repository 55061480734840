import { BrandContext } from './BrandProvider';
import { themeConfig } from '../../themes/boost/themeConfig';
import { createTheme } from '@ovotech/nebula';

const BrandContextBoost: BrandContext = {
  brand: 'boost',
  theme: createTheme(themeConfig),
  copy: {
    title: 'Boost - Energy Bills Support Scheme',
  },
  links: {
    ebssScheme: 'https://www.boostpower.co.uk/help/support/energy-bills-support-scheme',
  },
};

export default BrandContextBoost;
