import { Notification, P, Stack, TextField, EmailField, Margin } from '@ovotech/nebula';
import React, { useEffect, useState, useContext } from 'react';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import {
  useDpaCheckLazyQuery,
  DpaCheckQueryVariables,
  useGetSupportDetailsLazyQuery,
  GetSupportDetailsQuery,
} from '../../../__generated__/graphql';
import FormSection from '../../components/form-section/FormSection';
import NextButton from '../../components/next-button/NextButton';
import { useTrack } from '../../analytics';
import './AccountDetails.css';
import ErrorNotification from '../../components/error-notification/ErrorNotification';
import LoadingModal from '../../components/loading-modal/LoadingModal';
import AppTitle from '../../components/app-title/AppTitle';
import { BrandContext } from '../../providers/brand/BrandProvider';
import { State } from '../../state';
import { isPostcodeInScotland } from '../../isPostcodeInScotland';
import { WithResponseHeaderDate } from '../../../apollo';
import { isWhdSchemeOpen } from '../../isWhdSchemeOpen';

// RegExp from identity https://github.com/ovotech/identity-admin/blob/61456fc39e91d2e1b3f4bf2f99866829a5d6bd8f/app/identity/admin/domain/model/package.scala#L127
const POSTCODE_REGEXP =
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;

/* RegExp based on identity https://github.com/ovotech/identity-admin/blob/61456fc39e91d2e1b3f4bf2f99866829a5d6bd8f/app/identity/admin/domain/model/package.scala#L57
   Modified to support uppercase characters*/
const EMAIL_ADDRESS_REGEXP =
  /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/;

interface FormInput {
  accountNumber: string;
  emailAddress: string;
  postcode: string;
}

function getAccountNumberError(error?: FieldError): string | undefined {
  if (!error) {
    return;
  }
  if (error.type === 'required') {
    return 'Your account number is required';
  }
  if (error.type === 'pattern') {
    return 'Your account number should only contain numbers';
  }
}

function getEmailAddressError(error?: FieldError): string | undefined {
  if (!error) {
    return;
  }
  if (error.type === 'required') {
    return 'Your email is required';
  }
  if (error.type === 'pattern') {
    return 'Please enter a valid email';
  }
}

function getPostcodeError(error?: FieldError): string | undefined {
  if (!error) {
    return;
  }
  if (error.type === 'required') {
    return 'Your postcode is required';
  }
  if (error.type === 'pattern') {
    return 'Please enter a valid postcode';
  }
}
interface Props {
  onComplete: (state: State) => void;
}

const AccountDetails: React.FC<Props> = ({ onComplete }) => {
  const track = useTrack();
  const [submittedPostcode, setSubmittedPostcode] = useState<string>();

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
  } = useForm<FormInput>();

  const [
    getDpaCheck,
    { data: dpaCheckQueryData, error: dpaCheckQueryError, loading: dpaCheckQueryLoading },
  ] = useDpaCheckLazyQuery();

  const [
    getSupportDetails,
    { data: supportDetails, error: supportDetailsError, loading: supportDetailsLoading },
  ] = useGetSupportDetailsLazyQuery();

  const onSubmit: SubmitHandler<FormInput> = (formInput) => {
    const variables: DpaCheckQueryVariables = {
      details: formInput,
    };
    setSubmittedPostcode(formInput.postcode);
    void getDpaCheck({ variables });
  };

  useEffect(() => {
    if (!dpaCheckQueryLoading && dpaCheckQueryData) {
      const { areDpaDetailsCorrect: passed } = dpaCheckQueryData;
      track('Dpa check', { passed }, false);
      if (passed) {
        void getSupportDetails();
      }
    }
    if (dpaCheckQueryError) {
      track('Dpa check error', undefined, false);
    }
  }, [dpaCheckQueryData, dpaCheckQueryLoading, dpaCheckQueryError]);

  useEffect(() => {
    if (!supportDetailsLoading && supportDetails) {
      track('Get support details success', undefined, false);
      const { warmHomeDiscountState, responseHeaderDate } =
        supportDetails as WithResponseHeaderDate<GetSupportDetailsQuery>;
      onComplete({
        warmHomeDiscountState,
        isPostcodeInScotland: isPostcodeInScotland(submittedPostcode as string),
        isWhdSchemeOpen: isWhdSchemeOpen(responseHeaderDate),
      });
    }
    if (supportDetailsError) {
      track('Get support details error', undefined, false);
    }
  }, [supportDetails, supportDetailsLoading, supportDetailsError]);

  return (
    <div className="account-details">
      <FormSection className="account-details_form-section">
        <LoadingModal isOpen={dpaCheckQueryLoading || supportDetailsLoading}>
          We're fetching your account details.
        </LoadingModal>
        {useContext(BrandContext).brand === 'ovo' && (
          <div className="account-details_logos">
            <span className="account-details_logos-ovo">OVO</span>
            <span className="account-details_logos-boost">Boost</span>
          </div>
        )}
        <AppTitle />
        <Margin bottom={[5]} top={[5]}>
          <P className="account-details_description">
            Whether you’re with OVO Energy or Boost - we’re here for you. Provide us with a few
            details, so that you can see the support you can receive on your energy payments.
          </P>
        </Margin>
        {dpaCheckQueryData?.areDpaDetailsCorrect === false && (
          <ErrorNotification id="unrecognised-msg">
            Sorry, we don't recognise your details. Please check them and try again if they are
            incorrect.
          </ErrorNotification>
        )}
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Stack spaceBetween={6}>
            <div data-test="form-field-group-account-number">
              <Controller
                name="accountNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  pattern: /^\d+$/,
                }}
                render={({ field }) => (
                  <TextField
                    id="accountNumber"
                    data-test="account-number-input"
                    label="Account number"
                    hint="You can find this on your energy bill, in your online account, or in emails."
                    error={getAccountNumberError(formErrors.accountNumber)}
                    {...field}
                  />
                )}
              />
            </div>
            <div data-test="form-field-group-email-address">
              <Controller
                name="emailAddress"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  pattern: EMAIL_ADDRESS_REGEXP,
                }}
                render={({ field }) => (
                  <EmailField
                    id="emailAddress"
                    data-test="email-address-input"
                    label="Email address on your account"
                    error={getEmailAddressError(formErrors.emailAddress)}
                    {...field}
                  />
                )}
              />
            </div>
            <div data-test="form-field-group-postcode">
              <Controller
                name="postcode"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  pattern: POSTCODE_REGEXP,
                }}
                render={({ field }) => (
                  <TextField
                    id="postcode"
                    data-test="postcode-input"
                    label="Postcode"
                    error={getPostcodeError(formErrors.postcode)}
                    {...field}
                  />
                )}
              />
            </div>
            <NextButton isSubmit trackOptions={['Account details submitted']} />
            {dpaCheckQueryError && (
              <Notification id="dpa-error">
                <P>{dpaCheckQueryError.message}</P>
              </Notification>
            )}
            {supportDetailsError && (
              <Notification id="details-error">
                <P>Sorry, something went wrong. Please try again later</P>
              </Notification>
            )}
          </Stack>
        </form>
      </FormSection>
    </div>
  );
};

export default AccountDetails;
