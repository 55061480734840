import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

export type WithResponseHeaderDate<T> = T & {
  responseHeaderDate: Date | undefined;
};

export function createApolloClient(
  apolloServerUrl: string,
  authTokenStorageKey: string,
): ApolloClient<NormalizedCacheObject> {
  const httpLink = createHttpLink({
    uri: apolloServerUrl,
  });

  const setAuthHeader = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem(authTokenStorageKey);
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : null,
      },
    });
    return forward(operation);
  });

  const getAuthHeader = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;

      if (headers) {
        localStorage.setItem(authTokenStorageKey, headers.get('X-Auth-Token'));
      }
      return response;
    });
  });

  const addResponseHeaderDateLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;
      if (response.data && !response.data.responseHeaderDate) {
        try {
          const date: string = headers.get('Date');
          response.data.responseHeaderDate = date ? new Date(date) : undefined;
        } catch {
          response.data.responseHeaderDate = undefined;
        }
      }
      return response;
    });
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV !== 'production',
    link: from([setAuthHeader, getAuthHeader, addResponseHeaderDateLink, httpLink]),
    defaultOptions: {
      mutate: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
  });
}
