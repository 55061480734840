import { Disclosure } from '@ovotech/nebula';
import { addWeeks, differenceInWeeks, format } from 'date-fns';
import React from 'react';
import './WhdCard.css';
import InfoButton from '../info-button/InfoButton';
import { WarmHomeDiscountState } from '../../../__generated__/graphql';

function getPaymentMethodText(whdState: WarmHomeDiscountState): string | undefined {
  switch (whdState.paymentMethod) {
    case 'Cheque':
      return 'Cheque in the post';
    case 'Smets1':
    case 'Smets2':
    case 'Dppm':
      return `Credit to your ${whdState.paidToGas ? 'gas' : 'electricity'} meter`;
    case 'Credit':
      return 'Credit to your OVO account';
  }
  return undefined;
}

export function getDueByText(
  appliedAtDate: string | undefined,
  now = new Date(Date.now()),
): string {
  if (appliedAtDate) {
    const due = addWeeks(new Date(appliedAtDate), 6);
    const weeks = differenceInWeeks(due, now, { roundingMethod: 'ceil' });
    if (weeks > 1) {
      return `within the next ${weeks} weeks`;
    } else {
      return 'within the next week';
    }
  } else {
    return 'within the next 6 weeks';
  }
}

interface Props {
  state: WarmHomeDiscountState;
}

const WhdCardState: React.FC<Props> = ({ state }) => {
  const { appliedAt, paymentIssuedDate, paymentMethod } = state;
  const isPaid = !!paymentIssuedDate;
  const paymentMethodText = getPaymentMethodText(state);

  return (
    <>
      <div className="whd-card_whats-this" data-test="whd-whats-this">
        <Disclosure title="What is this scheme about?">
          <p>
            The Warm Home Discount is a one-off payment that gives you £150 towards your energy
            costs – and it’s open to people living in England, Scotland, and Wales.
          </p>
          <p>
            The scheme is funded by participating energy suppliers (OVO Energy is one of them) and
            it’s designed to help people who need it most.{' '}
          </p>
        </Disclosure>
      </div>
      <div className="whd-card_payment-details">
        <div className="whd-card_label">Discount amount</div>
        <div className="whd-card_value">£150</div>
        {paymentMethodText && (
          <>
            <div className="whd-card_label">Discount paid as a</div>
            <div
              className={`whd-card_value ${!isPaid ? 'whd-card_value-small' : ''}`}
              data-test="whd-payment-method"
            >
              {paymentMethodText}
            </div>
          </>
        )}
        {!isPaid && (
          <>
            <div className="whd-payment-due-by">
              <div className="whd-card_label">Due by</div>
              <div
                className={`whd-card_value ${!isPaid ? 'whd-card_value-small' : ''}`}
                data-test="whd-payment-due"
              >
                {getDueByText(appliedAt)}
              </div>
              {paymentMethod != 'Cheque' && (
                <p>
                  We will write to you via email or post to let you know when the payment has been
                  made.
                </p>
              )}
            </div>
          </>
        )}
        {isPaid && (
          <>
            <div className="whd-card_label">Payment issue date</div>
            <div
              className="whd-card_value whd-card_value-check"
              data-test="whd-payment-issued"
            >{`Paid on ${format(new Date(paymentIssuedDate), 'dd/MM/yyyy')}`}</div>
          </>
        )}
      </div>
      <div className="whd-card_advice">
        {!isPaid && (
          <>
            <h3>Recently moved home?</h3>
            <p>
              If you have moved home recently, switched away from OVO and haven’t received your
              confirmation yet, please be assured if you have provided your new address we will aim
              to send the cheque to your new address.
            </p>
            <h3>Want to learn more?</h3>
            <p>
              Our guide has all the information that we and our call centre team have on the Warm
              Home Discount.
            </p>
          </>
        )}
        <InfoButton
          href="https://www.ovoenergy.com/guides/energy-guides/warm-home-discount-scheme"
          text="Learn more"
          trackOptions={['WHD help link clicked']}
          dataTest="whd-learn-more-link"
        ></InfoButton>
      </div>
    </>
  );
};

export default WhdCardState;
