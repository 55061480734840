import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer_copyright">&copy; 2022 OVO Energy Limited</div>
      <ul className="footer_links">
        <li>
          <a href="https://www.ovoenergy.com/terms" className="footer_terms">
            Terms&nbsp;&amp;&nbsp;Conditions
          </a>
        </li>
        <li>
          <a href="https://www.ovoenergy.com/privacy-policy" className="footer_privacy">
            Privacy&nbsp;policy
          </a>
        </li>
        <li>
          <a href="https://help.ovoenergy.com" className="footer_helpcentre">
            Help&nbsp;Centre
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
