import React, { useContext } from 'react';
import { BrandContext } from './ebss/providers/brand/BrandProvider';
import { ApolloProvider } from '@apollo/react-hooks';
import Wrapper from './ebss/wrapper/Wrapper';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createApolloClient } from './apollo';
import { AnalyticsContext, createAnalytics } from './ebss/analytics';
import { apiUrl, authTokenStorageKey } from './config';
import { isProd } from './ebss/utils';

const apolloClient = createApolloClient(`${apiUrl}/graphql`, authTokenStorageKey);
const analytics = createAnalytics();

const oneTrustId = `38157829-8eb2-4d61-9af7-80ae2ce63dfb${!isProd(apiUrl) ? '-test' : ''}`;

const App: React.FC = () => {
  const brandContext = useContext(BrandContext);
  return (
    <HelmetProvider>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: 'function OptanonWrapper() {}',
          },
        ]}
      >
        {/* OneTrust Cookies Consent Notice start for smart-meter-help.ovoenergy.com */}
        <script
          src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script={oneTrustId}
        />
        {/* OneTrust Cookies Consent Notice end for smart-meter-help.ovoenergy.com */}

        <link
          href="https://fonts.googleapis.com/css2?family=Baloo:wght@400;700&family=Montserrat:wght@400;700&family=Open+Sans:wght@400;700"
          rel="stylesheet"
        />

        <title>{brandContext.copy.title}</title>
        <meta name="description" content="Track your support with energy bills" />
        <link rel="icon" href={`favicon-${brandContext.brand}.ico`} />
        <link rel="apple-touch-icon" href={`logo-${brandContext.brand}.png`} />
        <link rel="manifest" href={`manifest-${brandContext.brand}.webmanifest`} />
      </Helmet>
      <ApolloProvider client={apolloClient as any}>
        <AnalyticsContext.Provider value={analytics}>
          <Wrapper />
        </AnalyticsContext.Provider>
      </ApolloProvider>
    </HelmetProvider>
  );
};

export default App;
