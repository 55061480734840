import { datadogRum } from '@datadog/browser-rum';

const reportDatadogRum = ({ env, version }: { env: string; version?: string }): void =>
  datadogRum.init({
    applicationId: '08e99cab-1fab-4b88-974c-849f68b4cd40',
    clientToken: 'pubafa1679882d125e108357e6f620bb6e5',
    site: 'datadoghq.com',
    service: 'mit-self-service-web-journeys',
    env,
    version,
    sampleRate: 100,
    premiumSampleRate: 100,
  });

export default reportDatadogRum;
