export const td9_td12_postcodes = `TD90AA
TD90AB
TD90AD
TD90AE
TD90AF
TD90AG
TD90AH
TD90AJ
TD90AL
TD90AN
TD90AP
TD90AQ
TD90AR
TD90AS
TD90AT
TD90AU
TD90AW
TD90AX
TD90AY
TD90AZ
TD90BA
TD90BB
TD90BD
TD90BE
TD90BF
TD90BG
TD90BH
TD90BJ
TD90BL
TD90BN
TD90BP
TD90BQ
TD90BS
TD90BT
TD90BU
TD90BW
TD90BX
TD90BY
TD90BZ
TD90DA
TD90DB
TD90DD
TD90DE
TD90DF
TD90DG
TD90DH
TD90DJ
TD90DL
TD90DN
TD90DP
TD90DQ
TD90DR
TD90DS
TD90DT
TD90DU
TD90DW
TD90DX
TD90DY
TD90DZ
TD90EA
TD90EG
TD90EH
TD90EJ
TD90EL
TD90EN
TD90EP
TD90EQ
TD90ER
TD90ES
TD90ET
TD90EU
TD90EW
TD90EX
TD90EY
TD90EZ
TD90HA
TD90HE
TD90HF
TD90HG
TD90HH
TD90HJ
TD90HL
TD90HN
TD90HP
TD90HQ
TD90HR
TD90HS
TD90HT
TD90HU
TD90HW
TD90HX
TD90HY
TD90HZ
TD90JA
TD90JB
TD90JD
TD90JE
TD90JF
TD90JG
TD90JP
TD90JQ
TD90JR
TD90JS
TD90JT
TD90JU
TD90JX
TD90JY
TD90JZ
TD90LA
TD90LB
TD90LD
TD90LE
TD90LF
TD90LG
TD90LH
TD90LJ
TD90LL
TD90LN
TD90LP
TD90LQ
TD90LR
TD90LS
TD90LT
TD90LU
TD90LW
TD90LX
TD90LY
TD90LZ
TD90NA
TD90NB
TD90ND
TD90NJ
TD90NL
TD90NN
TD90NP
TD90NR
TD90NS
TD90NT
TD90NW
TD90NY
TD90NZ
TD90PA
TD90PB
TD90PD
TD90PE
TD90PF
TD90PG
TD90PH
TD90PJ
TD90PL
TD90PN
TD90PP
TD90PQ
TD90PR
TD90PS
TD90PT
TD90PU
TD90PW
TD90QA
TD90QD
TD90QE
TD90QF
TD90QG
TD90QH
TD90QJ
TD90QL
TD90QN
TD90QP
TD90QQ
TD90QR
TD90QS
TD90QT
TD90QU
TD90QW
TD90QX
TD90QY
TD90QZ
TD90RA
TD90RB
TD90RD
TD90RE
TD90RF
TD90RG
TD90RH
TD90RJ
TD90RL
TD90RN
TD90RP
TD90RQ
TD90RS
TD90RT
TD90RU
TD90RW
TD90RX
TD90RZ
TD90SA
TD90SB
TD90SD
TD90SE
TD90SF
TD90SG
TD90SH
TD90SJ
TD90SL
TD90SN
TD90SP
TD90SQ
TD90SR
TD90SS
TD90ST
TD90SW
TD90TA
TD90TB
TD90TD
TD90TE
TD90TF
TD90TG
TD90TH
TD90TL
TD90TN
TD90TQ
TD90TU
TD90TX
TD90TY
TD90WW
TD90WX
TD90XZ
TD90YA
TD90YB
TD90YD
TD90ZW
TD90ZX
TD91AD
TD91AE
TD91AF
TD91AH
TD97AA
TD97AB
TD97AD
TD97AE
TD97AF
TD97AG
TD97AH
TD97AJ
TD97AL
TD97AN
TD97AP
TD97AQ
TD97AR
TD97AW
TD97AX
TD97AY
TD97BA
TD97BB
TD97BD
TD97BE
TD97BG
TD97BH
TD97BJ
TD97BL
TD97BN
TD97BP
TD97BQ
TD97BS
TD97BU
TD97BW
TD97BX
TD97BY
TD97BZ
TD97DA
TD97DB
TD97DN
TD97DP
TD97DR
TD97DS
TD97DT
TD97DU
TD97DW
TD97DX
TD97DY
TD97DZ
TD97EA
TD97EB
TD97ED
TD97EE
TD97EF
TD97EG
TD97EH
TD97EJ
TD97EL
TD97EN
TD97EP
TD97EQ
TD97ER
TD97ES
TD97ET
TD97EW
TD97HD
TD97HE
TD97HF
TD97HG
TD97HH
TD97HJ
TD97HL
TD97HN
TD97HP
TD97HQ
TD97HR
TD97HS
TD97HT
TD97HU
TD97HW
TD97HX
TD97HY
TD97HZ
TD97JA
TD97JB
TD97JD
TD97JE
TD97JF
TD97JG
TD97JH
TD97JJ
TD97JL
TD97JQ
TD97JS
TD97JT
TD97JU
TD97JX
TD97JY
TD97JZ
TD97LA
TD97LG
TD97LH
TD97LJ
TD97LL
TD97LN
TD97LP
TD97LQ
TD97LR
TD97LS
TD97LT
TD97LU
TD97LW
TD97LX
TD97LY
TD97LZ
TD97NA
TD97NB
TD97ND
TD97NE
TD97NF
TD97NG
TD97NP
TD97NQ
TD97NR
TD97NS
TD97NT
TD97NU
TD97NX
TD97NY
TD97PE
TD97PF
TD97PG
TD97PH
TD97PJ
TD97PL
TD97PN
TD97PP
TD97PQ
TD97PR
TD97PS
TD97PW
TD97QA
TD97QB
TD97QD
TD97QE
TD97QF
TD97QG
TD97QH
TD97QJ
TD97QL
TD97QN
TD97QP
TD97QQ
TD97QR
TD97QS
TD97QW
TD97WA
TD97WB
TD97WX
TD97YA
TD97YB
TD97YD
TD97YE
TD97YF
TD97YG
TD97YU
TD98AA
TD98AB
TD98AD
TD98AE
TD98AF
TD98AG
TD98AH
TD98AJ
TD98AL
TD98AN
TD98AP
TD98AQ
TD98AR
TD98AS
TD98AT
TD98AW
TD98AY
TD98AZ
TD98BA
TD98BB
TD98BD
TD98BE
TD98BG
TD98BH
TD98BL
TD98BQ
TD98BS
TD98BT
TD98BU
TD98BX
TD98BY
TD98BZ
TD98DA
TD98DB
TD98DD
TD98DE
TD98DF
TD98DG
TD98DH
TD98DJ
TD98DL
TD98DN
TD98DP
TD98DQ
TD98DR
TD98DS
TD98DT
TD98DU
TD98DW
TD98DX
TD98ED
TD98EE
TD98EF
TD98EG
TD98EH
TD98EJ
TD98EL
TD98EN
TD98EP
TD98EQ
TD98ER
TD98ES
TD98ET
TD98EU
TD98EW
TD98EX
TD98EY
TD98EZ
TD98HA
TD98HB
TD98HD
TD98HL
TD98HN
TD98HP
TD98HR
TD98HS
TD98HT
TD98HU
TD98HW
TD98HX
TD98HY
TD98HZ
TD98JA
TD98JB
TD98JD
TD98JE
TD98JF
TD98JG
TD98JH
TD98JJ
TD98JL
TD98JN
TD98JP
TD98JQ
TD98JR
TD98JS
TD98JT
TD98JU
TD98JW
TD98JX
TD98LA
TD98LB
TD98LD
TD98LE
TD98LF
TD98LG
TD98LH
TD98LJ
TD98LL
TD98LN
TD98LP
TD98LQ
TD98LR
TD98LS
TD98LT
TD98LU
TD98LW
TD98LX
TD98LY
TD98LZ
TD98NA
TD98NB
TD98ND
TD98NE
TD98NF
TD98NG
TD98NH
TD98NJ
TD98NL
TD98NN
TD98NP
TD98NQ
TD98NR
TD98NS
TD98NT
TD98NU
TD98NW
TD98NX
TD98NY
TD98NZ
TD98PA
TD98PB
TD98PD
TD98PE
TD98PF
TD98PG
TD98PH
TD98PJ
TD98PL
TD98PN
TD98PP
TD98PQ
TD98PR
TD98PS
TD98PT
TD98PU
TD98PW
TD98PX
TD98PY
TD98PZ
TD98QA
TD98QB
TD98QD
TD98QE
TD98QG
TD98QH
TD98QJ
TD98QL
TD98QN
TD98QP
TD98QQ
TD98QR
TD98QS
TD98QT
TD98QU
TD98QW
TD98QX
TD98QY
TD98QZ
TD98RA
TD98RB
TD98RD
TD98RH
TD98RJ
TD98RL
TD98RN
TD98RP
TD98RR
TD98RS
TD98RT
TD98RU
TD98RW
TD98RX
TD98RY
TD98RZ
TD98SA
TD98SB
TD98SD
TD98SE
TD98SF
TD98SG
TD98SH
TD98SJ
TD98SL
TD98SQ
TD98ST
TD98SU
TD98SW
TD98SX
TD98SY
TD98SZ
TD98TA
TD98TB
TD98TD
TD98TE
TD98TF
TD98TG
TD98TH
TD98TJ
TD98TL
TD98TN
TD98TP
TD98TQ
TD98TR
TD98TS
TD98TW
TD98WA
TD98WX
TD98YA
TD98YB
TD98YD
TD98YE
TD98YF
TD98YG
TD98YH
TD98YL
TD98YN
TD98YP
TD98YQ
TD99AA
TD99AB
TD99BA
TD99BB
TD99BD
TD99BE
TD99BG
TD99BH
TD99BJ
TD99BL
TD99BN
TD99BP
TD99BQ
TD99BS
TD99BT
TD99BU
TD99BW
TD99BX
TD99BY
TD99BZ
TD99DA
TD99DB
TD99DD
TD99DE
TD99DF
TD99DG
TD99DH
TD99DJ
TD99DL
TD99DN
TD99DP
TD99DQ
TD99DR
TD99DT
TD99DW
TD99DY
TD99DZ
TD99EA
TD99EB
TD99EE
TD99EF
TD99EG
TD99EH
TD99EJ
TD99EL
TD99EN
TD99EP
TD99EQ
TD99ER
TD99ES
TD99ET
TD99EU
TD99EW
TD99EX
TD99EY
TD99EZ
TD99HA
TD99HB
TD99HD
TD99HE
TD99HF
TD99HL
TD99HN
TD99HP
TD99HR
TD99HS
TD99HT
TD99HU
TD99HW
TD99HX
TD99HY
TD99HZ
TD99JA
TD99JB
TD99JD
TD99JE
TD99JF
TD99JG
TD99JH
TD99JJ
TD99JL
TD99JN
TD99JP
TD99JQ
TD99JR
TD99JS
TD99JT
TD99JU
TD99JW
TD99LD
TD99LE
TD99LF
TD99LG
TD99LH
TD99LJ
TD99LL
TD99LN
TD99LP
TD99LQ
TD99LR
TD99LS
TD99LT
TD99LU
TD99LW
TD99LX
TD99LY
TD99LZ
TD99NA
TD99NB
TD99ND
TD99NE
TD99NF
TD99NN
TD99NP
TD99NQ
TD99NR
TD99NS
TD99NT
TD99NU
TD99NW
TD99NX
TD99NY
TD99NZ
TD99PA
TD99PB
TD99PD
TD99PE
TD99PF
TD99PG
TD99PH
TD99PJ
TD99PL
TD99PN
TD99PP
TD99PQ
TD99PR
TD99PS
TD99PW
TD99PX
TD99PY
TD99PZ
TD99QA
TD99QB
TD99QD
TD99QE
TD99QF
TD99QG
TD99QH
TD99QJ
TD99QL
TD99QP
TD99QQ
TD99QR
TD99QS
TD99QT
TD99QU
TD99QW
TD99QX
TD99RA
TD99RB
TD99RD
TD99RE
TD99RH
TD99RJ
TD99RL
TD99RN
TD99RP
TD99RQ
TD99RR
TD99RS
TD99RT
TD99RU
TD99RW
TD99RX
TD99RY
TD99RZ
TD99SA
TD99SB
TD99SD
TD99SE
TD99SF
TD99SG
TD99SL
TD99SN
TD99SP
TD99SQ
TD99SR
TD99SS
TD99ST
TD99SU
TD99SW
TD99SX
TD99SY
TD99SZ
TD99TA
TD99TB
TD99TD
TD99TE
TD99TF
TD99TG
TD99TH
TD99TJ
TD99TL
TD99TN
TD99TP
TD99TQ
TD99TW
TD99TY
TD99TZ
TD99UA
TD99UB
TD99UD
TD99UG
TD99UH
TD99UQ
TD99WX
TD99YA
TD99YB
TD99YD
TD99YE
TD99YG
TD99YH
TD99YL
TD99YQ
TD124AA
TD124AB
TD124AD
TD124AE
TD124AF
TD124AG
TD124AH
TD124AJ
TD124AL
TD124AN
TD124AP
TD124AQ
TD124AR
TD124AS
TD124AT
TD124AU
TD124AW
TD124AX
TD124AY
TD124AZ
TD124BA
TD124BB
TD124BD
TD124BE
TD124BF
TD124BG
TD124BH
TD124BJ
TD124BL
TD124BN
TD124BP
TD124BQ
TD124BS
TD124BT
TD124BU
TD124BW
TD124BX
TD124BY
TD124BZ
TD124DA
TD124DB
TD124DD
TD124DE
TD124DF
TD124DG
TD124DH
TD124DJ
TD124DL
TD124DN
TD124DP
TD124DQ
TD124DR
TD124DS
TD124DT
TD124DU
TD124DW
TD124DX
TD124DY
TD124DZ
TD124EA
TD124EB
TD124ED
TD124EE
TD124EF
TD124EG
TD124EH
TD124EL
TD124EN
TD124EP
TD124EQ
TD124ER
TD124ES
TD124ET
TD124EU
TD124EW
TD124EX
TD124EY
TD124EZ
TD124FA
TD124FD
TD124FE
TD124FF
TD124FG
TD124HA
TD124HB
TD124HD
TD124HE
TD124HF
TD124HG
TD124HH
TD124HJ
TD124HN
TD124HQ
TD124HT
TD124HU
TD124HX
TD124HY
TD124HZ
TD124JA
TD124JB
TD124JD
TD124JE
TD124JF
TD124JG
TD124JH
TD124JJ
TD124JL
TD124JN
TD124JP
TD124JQ
TD124JR
TD124JS
TD124JT
TD124JU
TD124JW
TD124JX
TD124JY
TD124JZ
TD124LA
TD124LF
TD124LG
TD124LH
TD124LJ
TD124LL
TD124LN
TD124LP
TD124LQ
TD124LR
TD124LS
TD124LT
TD124LU
TD124LW
TD124LX
TD124LY
TD124LZ
TD124NA
TD124NB
TD124ND
TD124NE
TD124NF
TD124NG
TD124NH
TD124NJ
TD124NL
TD124NN
TD124NQ
TD124NS
TD124NT
TD124NU
TD124NW
TD124QU
TD124WA
TD124WD
TD124WX
TD124XE
TD124YA
TD124YB
TD124YD
TD124YE
TD124YF
TD124YG
TD124YH
TD124YJ
TD124YL
TD124YP
TD124YQ
TD124YR
TD129AA
TD129AB
TD129AD
TD129AE
TD129AF`;
