import { td9_td12_postcodes } from '../data/td9_td12_postcodes';

const postcodes = td9_td12_postcodes.split('\n');

export function isPostcodeInScotland(postcode: string): boolean {
  postcode = postcode.toUpperCase().replaceAll(' ', '');
  if (postcode.startsWith('TD9') || postcode.startsWith('TD12')) {
    return postcodes.includes(postcode);
  } else {
    const matchResult = areaRegex.exec(postcode);
    if (!matchResult) {
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, prefix] = matchResult;
    return scottishPostcodePrefixes.includes(prefix);
  }
}

const scottishPostcodePrefixes = [
  'AB',
  'DD',
  'DG',
  'EH',
  'FK',
  'G',
  'HS',
  'IV',
  'KA',
  'KW',
  'KY',
  'ML',
  'PA',
  'PH',
  'TD',
  'ZE',
];

const areaRegex = /^([A-Z]{1,2})\d{1,2}/;
