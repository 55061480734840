import React, { useState } from 'react';
import AnchorTag from '../../components/anchor-tag/AnchorTag';
import WhdCard from '../../components/whd-card/WhdCard';
import CustomerSupportCard from '../../components/customer-support-card/CustomerSupportCard';
import CsatCard from '../../components/csat-card/CsatCard';
import './SupportDetails.css';
import AppTitle from '../../components/app-title/AppTitle';
import { State } from '../../state';
import ScrollToTopButton from '../../components/scroll-to-top-button/ScrollToTopButton';
import { isSupportedWarmHomeDiscountStateGroup } from '../../isSupportedWarmHomeDiscountStateGroup';

enum SectionName {
  WhdDetails = 'WhdDetails',
  CustomerSupportDetails = 'CustomerSupportDetails',
}

interface Props {
  state: State;
  onExit(): void;
}

const SupportDetails: React.FC<Props> = ({ state, onExit }) => {
  const { warmHomeDiscountState, isPostcodeInScotland, isWhdSchemeOpen } = state;
  const [activeSection, setActiveSection] = useState<SectionName | undefined>(undefined);
  const [hasCsatCardLoaded, setHasCsatCardLoaded] = useState(false);

  function navigateTo(sectionName: SectionName) {
    setActiveSection(sectionName);
  }

  function getSectionClassNames(sectionName: SectionName): string {
    return (
      'support-details_section ' +
      (activeSection === sectionName ? 'support-details_active-section' : '')
    );
  }

  function resetSections() {
    setActiveSection(undefined);
  }

  const isScottishWithNoWhdState = isPostcodeInScotland && !warmHomeDiscountState;
  const hasSupportedWhdStateGroup =
    (warmHomeDiscountState &&
      isSupportedWarmHomeDiscountStateGroup(warmHomeDiscountState.stateGroup)) ??
    false;

  const showWhd = !isWhdSchemeOpen || isScottishWithNoWhdState || hasSupportedWhdStateGroup;

  return (
    <div className={'support-details ' + (activeSection ? 'support-details_section-active' : '')}>
      <div className="support-details_content">
        <div className="support-details_header">
          <button
            className="support-details_exit"
            data-test="support-details_exit"
            onClick={() => onExit()}
          >
            Back
          </button>
          <AppTitle />
          <p className="support-details_intro">Support packages to help with your energy bills.</p>
          <p className="support-details_on-this-page">On this page:</p>
          <nav className="support-details_top-nav">
            {showWhd && (
              <AnchorTag
                trackOptions={['Warm home discount top link clicked']}
                text="Warm Home Discount"
                dataTest="whd-top-link"
                onClick={() => navigateTo(SectionName.WhdDetails)}
                linkToElementId="whdCard"
              />
            )}
            <AnchorTag
              trackOptions={['Customer support package top link clicked']}
              text="OVO's Support Package"
              dataTest="customer-support-top-link"
              onClick={() => navigateTo(SectionName.CustomerSupportDetails)}
              linkToElementId="customerSupportCard"
            />
          </nav>
        </div>
        <div className="support-details_back">
          <a href="#" onClick={() => resetSections()}>
            Back
          </a>
        </div>
        {showWhd && (
          <div id="whdCard" className={getSectionClassNames(SectionName.WhdDetails)}>
            <WhdCard
              isOpen={isWhdSchemeOpen}
              warmHomeDiscountState={warmHomeDiscountState}
              isPostcodeInScotland={isPostcodeInScotland}
            />
          </div>
        )}
        <div
          id="customerSupportCard"
          className={getSectionClassNames(SectionName.CustomerSupportDetails)}
        >
          <CustomerSupportCard />
        </div>
      </div>
      <div className={hasCsatCardLoaded ? 'support-details_csat' : 'support-details_csat-loading'}>
        <CsatCard onLoaded={() => setHasCsatCardLoaded(true)} />
      </div>
      {Object.keys(SectionName).length > 1 && (
        <nav className="support-details_bottom-nav">
          <h3>Explore more support options</h3>
          {showWhd && activeSection != SectionName.WhdDetails && (
            <AnchorTag
              trackOptions={['Warm home discount bottom link clicked']}
              text="Warm Home Discount"
              dataTest="whd-bottom-link"
              onClick={() => navigateTo(SectionName.WhdDetails)}
              linkToElementId="whdCard"
            />
          )}
          {activeSection != SectionName.CustomerSupportDetails && (
            <AnchorTag
              trackOptions={['Customer support package bottom link clicked']}
              text="OVO's Support Package"
              dataTest="customer-support-bottom-link"
              onClick={() => navigateTo(SectionName.CustomerSupportDetails)}
              linkToElementId="customerSupportCard"
            />
          )}
        </nav>
      )}
      {<ScrollToTopButton />}
    </div>
  );
};

export default SupportDetails;
