import { P } from '@ovotech/nebula';
import React from 'react';
import './WhdCard.css';
import InfoButton from '../info-button/InfoButton';

const WhdCardScotland: React.FC = () => {
  return (
    <div className="whd-card_scotland">
      <P>
        We can see that you live in Scotland, you may be eligible for a Warm Home Discount. Check if
        you’re eligible by reading our eligibility criteria.
      </P>
      <InfoButton
        href="https://www.ovoenergy.com/guides/energy-guides/warm-home-discount-scheme"
        text="Check my eligibility"
        trackOptions={['WHD Scotland eligibility link clicked']}
        dataTest="whd-eligibility-link"
        isAlternative
      ></InfoButton>
    </div>
  );
};

export default WhdCardScotland;
