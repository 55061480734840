import React, { PropsWithChildren } from 'react';
import './DetailsCard.css';

interface Props extends PropsWithChildren {
  className?: string;
}

const DetailsCard: React.FC<Props> = ({ children, className }) => {
  return <div className={`details-card ${className ?? ''}`}>{children}</div>;
};

export default DetailsCard;
