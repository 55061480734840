import Analytics, { AnalyticsInstance } from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import { createContext, useContext } from 'react';

export function createAnalytics(): AnalyticsInstance {
  return Analytics({
    app: 'mit-self-service-web-journeys-ebss-journey',
    plugins: [
      mixpanelPlugin({
        token: 'f39255dfe9c44a0bf730685c6babebad',
      }),
    ],
  });
}

export const AnalyticsContext = createContext<AnalyticsInstance | undefined>(undefined);

export function useTrack(): (
  event: string,
  payload?: Record<string, unknown>,
  isUiEvent?: boolean,
) => void {
  const analytics = useContext(AnalyticsContext);
  const appName = 'ebss';
  return (event, payload = {}, isUiEvent = true) => {
    const prefix = isUiEvent ? 'UI' : 'App';
    analytics?.track(`${appName}:${prefix}:${event}`, {
      ...payload,
      isUiEvent,
      appName,
    });
  };
}
