import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type Case = {
  __typename?: 'Case';
  id: Scalars['String']['output'];
};

export type CreateHealthCheckCaseInput = {
  caseComments: Scalars['String']['input'];
  meterType: Scalars['String']['input'];
};

export type CreateMismatchCaseInput = {
  elecCustomerMsns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  elecSystemMsns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gasCustomerMsns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gasSystemMsns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DpaCheckInput = {
  accountNumber: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type EbssPayment = {
  __typename?: 'EbssPayment';
  brand?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['DateTime']['output']>;
  monthlyValueAmount?: Maybe<Scalars['Float']['output']>;
  paymentReferenceNumber?: Maybe<Scalars['String']['output']>;
  paymentRequestCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** bacs | acc | smt | vou */
  refundDueVia?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export enum FuelType {
  Electricity = 'Electricity',
  Gas = 'Gas'
}

export enum MeterType {
  Other = 'OTHER',
  S1 = 'S1',
  S2 = 'S2'
}

export type Mutation = {
  __typename?: 'Mutation';
  createHealthCheckCase?: Maybe<Case>;
  createMismatchCase?: Maybe<Array<Case>>;
};


export type MutationCreateHealthCheckCaseArgs = {
  details: CreateHealthCheckCaseInput;
};


export type MutationCreateMismatchCaseArgs = {
  details: CreateMismatchCaseInput;
};

export type Query = {
  __typename?: 'Query';
  areAccountDetailsCorrect: Scalars['Boolean']['output'];
  areDpaDetailsCorrect: Scalars['Boolean']['output'];
  ebssPayments: Array<EbssPayment>;
  hasOpenSalesforceCase: Scalars['Boolean']['output'];
  supplyPoints: Array<SupplyPoint>;
  warmHomeDiscountState?: Maybe<WarmHomeDiscountState>;
};


export type QueryAreAccountDetailsCorrectArgs = {
  details: VerifyAccountInput;
};


export type QueryAreDpaDetailsCorrectArgs = {
  details: DpaCheckInput;
};

export type SupplyPoint = {
  __typename?: 'SupplyPoint';
  fuel: FuelType;
  isTwoRate?: Maybe<Scalars['Boolean']['output']>;
  mpxn: Scalars['String']['output'];
  msn?: Maybe<Scalars['String']['output']>;
  nonComms?: Maybe<Scalars['Boolean']['output']>;
  type: MeterType;
};

export type VerifyAccountInput = {
  accountNumber: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type WarmHomeDiscountState = {
  __typename?: 'WarmHomeDiscountState';
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  paidToGas?: Maybe<Scalars['Boolean']['output']>;
  paymentCommDate?: Maybe<Scalars['DateTime']['output']>;
  paymentIssuedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Smets2 | Dppm | Cheque | Smets1 | Credit */
  paymentMethod?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  /** PaymentSuccess | ExternalValidationFailure | AwaitingPayment | UnknownSupply | Duplicate | ApprovalException | ApprovalPending | ExternalValidation | PaymentInProgress */
  stateGroup?: Maybe<Scalars['String']['output']>;
};

export type DpaCheckQueryVariables = Exact<{
  details: DpaCheckInput;
}>;


export type DpaCheckQuery = { __typename?: 'Query', areDpaDetailsCorrect: boolean };

export type GetSupportDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupportDetailsQuery = { __typename?: 'Query', warmHomeDiscountState?: { __typename?: 'WarmHomeDiscountState', stateGroup?: string | null, paymentIssuedDate?: any | null, paidToGas?: boolean | null, paymentMethod?: string | null, appliedAt?: any | null } | null };

export type VerifyAccountQueryVariables = Exact<{
  details: VerifyAccountInput;
}>;


export type VerifyAccountQuery = { __typename?: 'Query', areAccountDetailsCorrect: boolean };


export const DpaCheckDocument = gql`
    query dpaCheck($details: DpaCheckInput!) {
  areDpaDetailsCorrect(details: $details)
}
    `;

/**
 * __useDpaCheckQuery__
 *
 * To run a query within a React component, call `useDpaCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useDpaCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDpaCheckQuery({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useDpaCheckQuery(baseOptions: Apollo.QueryHookOptions<DpaCheckQuery, DpaCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DpaCheckQuery, DpaCheckQueryVariables>(DpaCheckDocument, options);
      }
export function useDpaCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DpaCheckQuery, DpaCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DpaCheckQuery, DpaCheckQueryVariables>(DpaCheckDocument, options);
        }
export function useDpaCheckSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DpaCheckQuery, DpaCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DpaCheckQuery, DpaCheckQueryVariables>(DpaCheckDocument, options);
        }
export type DpaCheckQueryHookResult = ReturnType<typeof useDpaCheckQuery>;
export type DpaCheckLazyQueryHookResult = ReturnType<typeof useDpaCheckLazyQuery>;
export type DpaCheckSuspenseQueryHookResult = ReturnType<typeof useDpaCheckSuspenseQuery>;
export type DpaCheckQueryResult = Apollo.QueryResult<DpaCheckQuery, DpaCheckQueryVariables>;
export const GetSupportDetailsDocument = gql`
    query GetSupportDetails {
  warmHomeDiscountState {
    stateGroup
    paymentIssuedDate
    paidToGas
    paymentMethod
    appliedAt
  }
}
    `;

/**
 * __useGetSupportDetailsQuery__
 *
 * To run a query within a React component, call `useGetSupportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetSupportDetailsQuery, GetSupportDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupportDetailsQuery, GetSupportDetailsQueryVariables>(GetSupportDetailsDocument, options);
      }
export function useGetSupportDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupportDetailsQuery, GetSupportDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupportDetailsQuery, GetSupportDetailsQueryVariables>(GetSupportDetailsDocument, options);
        }
export function useGetSupportDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSupportDetailsQuery, GetSupportDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSupportDetailsQuery, GetSupportDetailsQueryVariables>(GetSupportDetailsDocument, options);
        }
export type GetSupportDetailsQueryHookResult = ReturnType<typeof useGetSupportDetailsQuery>;
export type GetSupportDetailsLazyQueryHookResult = ReturnType<typeof useGetSupportDetailsLazyQuery>;
export type GetSupportDetailsSuspenseQueryHookResult = ReturnType<typeof useGetSupportDetailsSuspenseQuery>;
export type GetSupportDetailsQueryResult = Apollo.QueryResult<GetSupportDetailsQuery, GetSupportDetailsQueryVariables>;
export const VerifyAccountDocument = gql`
    query VerifyAccount($details: VerifyAccountInput!) {
  areAccountDetailsCorrect(details: $details)
}
    `;

/**
 * __useVerifyAccountQuery__
 *
 * To run a query within a React component, call `useVerifyAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyAccountQuery({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useVerifyAccountQuery(baseOptions: Apollo.QueryHookOptions<VerifyAccountQuery, VerifyAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyAccountQuery, VerifyAccountQueryVariables>(VerifyAccountDocument, options);
      }
export function useVerifyAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyAccountQuery, VerifyAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyAccountQuery, VerifyAccountQueryVariables>(VerifyAccountDocument, options);
        }
export function useVerifyAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyAccountQuery, VerifyAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifyAccountQuery, VerifyAccountQueryVariables>(VerifyAccountDocument, options);
        }
export type VerifyAccountQueryHookResult = ReturnType<typeof useVerifyAccountQuery>;
export type VerifyAccountLazyQueryHookResult = ReturnType<typeof useVerifyAccountLazyQuery>;
export type VerifyAccountSuspenseQueryHookResult = ReturnType<typeof useVerifyAccountSuspenseQuery>;
export type VerifyAccountQueryResult = Apollo.QueryResult<VerifyAccountQuery, VerifyAccountQueryVariables>;