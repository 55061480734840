import { P, Disclosure, InfoNotification } from '@ovotech/nebula';
import React from 'react';
import './WhdCardClosed.css';
import InfoButton from '../info-button/InfoButton';

const WhdClosedCardScotland: React.FC = () => {
  return (
    <div className="whd-card-closed_scotland">
      <div className="whd-card-closed_whats-this" data-test="whd-closed-scotland-whats-this">
        <Disclosure title="What is this scheme about?">
          <p>
            The Warm Home Discount is a one-off payment that gives you £150 towards your energy
            costs – and it’s open to people living in England, Scotland, and Wales.
          </p>
          <p>
            The scheme is funded by participating energy suppliers (OVO Energy is one of them) and
            it’s designed to help people who need it most.
          </p>
        </Disclosure>
      </div>
      <InfoNotification
        id="whd-closed-notification_scotland"
        title="Closed"
        noMargin
        className="whd-card-closed_notification"
      >
        <P>
          The Warm Home Discount Scheme is now closed for applications. We will update this page
          when the scheme opens in autumn 2025.
        </P>
      </InfoNotification>
      <div className="whd-card-closed_learn-more" data-test="whd-closed-scotland-learn-more">
        <h3>Want to learn more?</h3>
        <p>
          We can see that you live in Scotland, you may be eligible for a Warm Home Discount.
          Pre-registration will open on the <b>1st April 2024</b>. Check if you’re eligible by
          reading our eligibility criteria.
        </p>
        <InfoButton
          href="https://www.ovoenergy.com/guides/energy-guides/warm-home-discount-scheme"
          text="Learn more"
          trackOptions={['WHD help link clicked']}
          dataTest="whd-closed-scotland-learn-more-link"
        ></InfoButton>
      </div>
    </div>
  );
};

export default WhdClosedCardScotland;
