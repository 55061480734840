import React, { useState } from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import AccountDetails from '../screens/AccountDetails/AccountDetails';
import SupportDetails from '../screens/SupportDetails/SupportDetails';
import { State } from '../state';
import './Wrapper.css';

const Wrapper: React.FC = () => {
  const [state, setState] = useState<State | null>(null);
  return (
    <div className="wrapper">
      <Header />
      <main>
        {state ? (
          <SupportDetails state={state} onExit={() => setState(null)} />
        ) : (
          <AccountDetails onComplete={(state) => setState(state)} />
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Wrapper;
