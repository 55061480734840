const fontSizes = ['1.2rem', '1.4rem', '1.6rem', '2.0rem', '2.4rem', '2.8rem', '3.2rem', '4.8rem'];
const brand = {
  focus: { base: '#000000' },
  primary: {
    dark: '#107896',
    darker: '#107896',
  },
};
export const themeConfig = {
  fonts: {
    body: "'Montserrat', sans-serif",
    display: "'Baloo', Montserrat",
  },
  colors: {
    focus: brand.focus.base,
    primary: brand.primary.dark,
    primaryAlt: brand.primary.darker,
    brand: brand,
  },
  fontSizes: fontSizes,
  responsiveFontSizes: {
    body: [fontSizes[2], fontSizes[2]],
    display: [fontSizes[2], fontSizes[2]],
  },
};
