import { useMemo } from 'react';

function useQuery() {
  const { queryString, urlSearchParams } = getUrlSearchParams();
  return useMemo(() => urlSearchParams, [queryString]);
}

function getUrlSearchParams(): { queryString: string; urlSearchParams: URLSearchParams } {
  const queryString = document.location.search;
  const urlSearchParams = new URLSearchParams(document.location.search);
  return { queryString, urlSearchParams };
}

export const useFeatureFlag = (useCache = true): ((featureName: string) => boolean) => {
  return (featureName: string) =>
    (useCache ? useQuery() : getUrlSearchParams().urlSearchParams).has(featureName);
};
