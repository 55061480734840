import { createContext } from 'react';
import { ThemeProvider } from 'styled-components';
import BrandContextOvo from './BrandContextOvo';
import BrandContextBoost from './BrandContextBoost';
import { Theme } from '@ovotech/nebula';

export type Brand = 'ovo' | 'boost';

export interface BrandContext {
  brand: Brand;
  theme: Theme;
  copy: {
    title: string;
  };
  links: {
    ebssScheme: string;
  };
}

export const BrandContext = createContext<BrandContext>(BrandContextOvo);

type Props = {
  children?: React.ReactNode;
  brand: Brand;
};

export const BrandProvider: React.FC<Props> = ({ children, brand }) => {
  const context = brand === 'boost' ? BrandContextBoost : BrandContextOvo;
  document.body.classList.add(`theme-${brand}`);
  return (
    <BrandContext.Provider value={context}>
      <ThemeProvider theme={context.theme}>{children}</ThemeProvider>
    </BrandContext.Provider>
  );
};
