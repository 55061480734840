import React from 'react';
import { useTrack } from '../../analytics';
import './AnchorTag.css';

interface Props {
  onClick: () => void;
  linkToElementId: string;
  trackOptions: [string, Record<string, unknown>?];
  text: string;
  dataTest: string;
}

const AnchorTag: React.FC<Props> = ({
  onClick,
  linkToElementId,
  trackOptions: [event, payload],
  text,
  dataTest,
}) => {
  const track = useTrack();

  function handleClick() {
    track(event, payload);
    if (onClick) onClick();
  }
  return (
    <a
      className="anchor-tag"
      data-test={dataTest}
      href={`#${linkToElementId}`}
      onClick={handleClick}
    >
      <span className="anchor-tag_text">{text}</span>
    </a>
  );
};

export default AnchorTag;
