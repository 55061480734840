import { getQualtricsIntercept } from './getQualtricsIntercept';

export function useQualtricsFeedback(): () => void {
  function runIntercept(): void {
    const qsiApi = (window as any).QSI.API;
    qsiApi.load().done(qsiApi.run());
  }

  function render(): void {
    const qsiApi = (window as any).QSI?.API;
    if (qsiApi) {
      qsiApi.unload();
      runIntercept();
    } else {
      window.addEventListener('qsi_js_loaded', runIntercept, false);
      getQualtricsIntercept();
    }
  }

  return render;
}
