import { Heading2, P } from '@ovotech/nebula';
import DetailsCard from '../details-card/DetailsCard';
import React from 'react';
import InfoButton from '../info-button/InfoButton';
import './CustomerSupportCard.css';

const CustomerSupportCard: React.FC = () => {
  return (
    <DetailsCard className="customer-support-card">
      <Heading2>OVO's Customer Support Package</Heading2>
      <P data-test="customer-support-intro">{`OVO’s Customer Support Package has been designed to help those of our customers who need it most this winter. Whether you’re with OVO Energy or Boost – we’re here for you.`}</P>
      <P data-test="customer-support-description">{`The package will be open from 30 October 2024 and will provide eligible customers who are struggling as a result of high energy prices with financial and practical support.`}</P>
      <InfoButton
        href="https://www.ovoenergy.com/winter-support-delivery/sign-up"
        text="Apply for support"
        trackOptions={['Support package link clicked']}
        dataTest="customer-support-apply-link"
        isAlternative
      ></InfoButton>
    </DetailsCard>
  );
};

export default CustomerSupportCard;
