import React, { useEffect, useState } from 'react';
import { useTrack } from '../../analytics';
import { useQualtricsFeedback } from '../../hooks/qualtrics/useQualtricsFeedback';
import './CsatCard.css';
import DetailsCard from '../details-card/DetailsCard';

interface Props {
  onLoaded: () => void;
}

const CsatCard: React.FC<Props> = ({ onLoaded }) => {
  const [isInitialised, setIsInitialised] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const track = useTrack();

  useEffect(() => {
    const targetNode = document.querySelector('.feedback');
    const renderQualtricsFeedback = useQualtricsFeedback();
    const observer = new MutationObserver(onMutation);

    function onMutation(mutations: MutationRecord[], observer: MutationObserver) {
      const hasNodeBeenAdded = mutations.some((mutation) => mutation.addedNodes.length > 0);
      if (hasNodeBeenAdded) {
        observer.disconnect();
        setIsLoaded(true);
      }
    }

    if (isLoaded) {
      onLoaded();
    } else if (!isInitialised && targetNode) {
      try {
        observer.observe(targetNode, { childList: true });
        renderQualtricsFeedback();
        setIsInitialised(true);
      } catch (error) {
        const errorMessage = (error as Error).message;
        track('Qualtrics Error', { errorMessage }, false);
      }
    }
  }, [isInitialised, isLoaded]);
  return <DetailsCard className="feedback" />;
};

export default CsatCard;
