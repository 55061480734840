import { Heading2 } from '@ovotech/nebula';
import DetailsCard from '../details-card/DetailsCard';
import React from 'react';
import './WhdCard.css';
import WhdCardScotland from './WhdCardScotland';
import WhdCardState from './WhdCardState';
import WhdCardScotlandClosed from './WhdCardScotlandClosed';
import WhdCardClosed from './WhdCardClosed';

import { WarmHomeDiscountState } from '../../../__generated__/graphql';

interface Props {
  isOpen: boolean;
  isPostcodeInScotland: boolean | null | undefined;
  warmHomeDiscountState: WarmHomeDiscountState | null | undefined;
}

const WhdCard: React.FC<Props> = ({ isOpen, isPostcodeInScotland, warmHomeDiscountState }) => {
  return (
    <DetailsCard className="whd-card">
      <Heading2>Warm Home Discount</Heading2>
      {!isOpen && isPostcodeInScotland && <WhdCardScotlandClosed />}
      {!isOpen && !isPostcodeInScotland && <WhdCardClosed />}
      {isOpen && !warmHomeDiscountState && isPostcodeInScotland && <WhdCardScotland />}
      {isOpen && warmHomeDiscountState && <WhdCardState state={warmHomeDiscountState} />}
    </DetailsCard>
  );
};

export default WhdCard;
