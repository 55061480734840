import { BrandContext } from './BrandProvider';
import { themeConfig } from '@ovotech/ovoenergy-theme';
import { createTheme } from '@ovotech/nebula';

const BrandContextOvo: BrandContext = {
  brand: 'ovo',
  theme: createTheme(themeConfig),
  copy: {
    title: 'OVO Energy - Energy Bills Support Scheme',
  },
  links: {
    ebssScheme: 'https://www.ovoenergy.com/energy-bills-support-scheme',
  },
};

export default BrandContextOvo;
