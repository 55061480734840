import React, { useRef } from 'react';
import { useTrack } from '../../analytics';
import './InfoButton.css';

interface Props {
  href: string;
  trackOptions: [string, Record<string, unknown>?];
  text: string;
  dataTest: string;
  className?: string;
  isAlternative?: boolean;
}

const InfoButton: React.FC<Props> = ({
  href,
  trackOptions: [event, payload],
  text,
  dataTest,
  className,
  isAlternative,
}) => {
  const track = useTrack();
  const ref = useRef<HTMLAnchorElement>(null);

  function handleClick() {
    track(event, payload);
    if (ref.current) ref.current.blur();
  }
  return (
    <a
      className={`info-button ${isAlternative ? 'info-button-alternative' : ''} ${className ?? ''}`}
      data-test={dataTest}
      href={href}
      ref={ref}
      onClick={handleClick}
    >
      <span className="info-button_text">{text}</span>
    </a>
  );
};

export default InfoButton;
