import { Heading1 } from '@ovotech/nebula';
import React from 'react';
import './AppTitle.css';

const AppTitle: React.FC = () => {
  return (
    <div className="app-title">
      <Heading1>Track support on your energy payments</Heading1>
    </div>
  );
};

export default AppTitle;
