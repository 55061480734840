export function isSupportedWarmHomeDiscountStateGroup(
  stateGroup: string | null | undefined,
): boolean {
  let isSupported = false;

  if (stateGroup)
    isSupported = ['DUPLICATE', 'PAYMENTSUCCESS', 'PAYMENTINPROGRESS', 'AWAITINGPAYMENT'].includes(
      stateGroup.toUpperCase(),
    );

  return isSupported;
}
