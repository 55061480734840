import { PropsWithChildren } from 'react';
import { Notification } from '@ovotech/nebula';
import './ErrorNotification.css';

interface Props extends PropsWithChildren {
  id: string;
}

const ErrorNotification: React.FC<Props> = ({ id, children }) => {
  return (
    <Notification id={id} variant="error" className="error-notification">
      {children}
    </Notification>
  );
};

export default ErrorNotification;
