import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.css';

const ScrollToTopButton: React.FC = () => {
  const hasScrolled = () => window.scrollY > 0;

  const [visible, setVisible] = useState(hasScrolled());

  const handleScroll = () => {
    setVisible(hasScrolled());
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visible]);

  return (
    <a
      href="#"
      className={`scroll-to-top-button ${visible ? 'scroll-to-top-button-visible' : ''}`}
    />
  );
};

export default ScrollToTopButton;
